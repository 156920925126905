import * as React from 'react';
import { Link } from '@remix-run/react';
import type { RemixLinkProps } from '@remix-run/react/dist/components';
import type { VariantProps } from 'class-variance-authority';

import { cn } from '@yonderlabs-packages/ui';

import { buttonVariants } from './Button';

interface LinkButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    RemixLinkProps,
    VariantProps<typeof buttonVariants> {
  to: string;
  disabled?: boolean;
  external?: boolean;
}

function LinkButton({ to, variant, size, disabled, block, external, className, ...rest }: LinkButtonProps) {
  return (
    <>
      {disabled ? (
        <p
          aria-disabled={disabled}
          role='link'
          className={cn(buttonVariants({ variant, size, className, block }) + ' cursor-not-allowed')}
        >
          {rest.children}
        </p>
      ) : external ? (
        <a
          href={to}
          target='_blank'
          rel='noopener noreferrer'
          className={cn(buttonVariants({ variant, size, className, block }))}
          {...rest}
        >
          {rest.children}
        </a>
      ) : (
        <Link className={cn(buttonVariants({ variant, size, className, block }))} to={to} {...rest}>
          {rest.children}
        </Link>
      )}
    </>
  );
}

export { LinkButton };
export type { LinkButtonProps };
